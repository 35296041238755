import type { SerializeFrom } from '@remix-run/node'
import type { loader as rootLoader } from '~/root'
import type { Role } from './db/schema'

import { useRouteLoaderData } from '@remix-run/react'

function isUser(
  user: unknown,
): user is SerializeFrom<typeof rootLoader>['user'] {
  return (
    !!user &&
    typeof user === 'object' &&
    'id' in user &&
    typeof user.id === 'string'
  )
}

export function useOptionalUser() {
  const data = useRouteLoaderData<typeof rootLoader>('root')
  if (!data || !isUser(data.user)) {
    return undefined
  }
  return data.user
}

export function useUser() {
  const maybeUser = useOptionalUser()
  if (!maybeUser) {
    throw new Error(
      'No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead.',
    )
  }
  return maybeUser
}

export function userHasRole(
  user: Pick<ReturnType<typeof useUser>, 'roles'> | null,
  role: Role['name'],
) {
  if (!user) return false
  return user.roles.some((r) => r.name === role)
}
